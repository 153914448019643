import React, { Component } from "react";
import "./page-not-found.css";



export class PageNotFound extends Component {
    public render() {
        return (
            <div>
                <h1>404</h1>
                <h2>Page not found</h2>
            </div>
        )
    }
}